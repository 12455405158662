import React, { useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';


const ORGANIZATION_LIST = gql`
query OrganizationStats($from:Date!, $until:Date!)
{
  admin{
    partnerOrganizations{
        _id
        name
        organizationNumber
        statsForMonth(from:$from, until:$until){
            registeredCount
            appliedCount
        }
    }
  }
}
`



export default () => {
  const [selectedDate, handleDateChange] = useState(dayjs()),
        { data } = useQuery(ORGANIZATION_LIST, { variables: {
          from: selectedDate.startOf('month').toISOString(),
          until: selectedDate.endOf('month').toISOString()
        } });

  return <Paper style={{ margin: 20, marginTop: 90, padding: 20, flex: 1, display: 'flex', flexDirection: 'column' }}>
    <DatePicker
      variant="inline"
      openTo="month"
      views={["year", "month"]}
      label="Välj tidsperiod"
      value={selectedDate}
      onChange={handleDateChange}
    />
    <Table style={{ marginTop: 20 }}>
      <TableHead>
        <TableRow>
          <TableCell>
                        Organisation
          </TableCell>
          <TableCell>
                        Antal registrerade
          </TableCell>
          <TableCell>
                        Antal inlösta
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { data && data.admin && data.admin.partnerOrganizations && data.admin.partnerOrganizations.map(po => <TableRow key={po._id}>
          <TableCell>
            { po.name }
          </TableCell>
          <TableCell>
            { po.statsForMonth.registeredCount }
          </TableCell>
          <TableCell>
            { po.statsForMonth.appliedCount }
          </TableCell>
                    
        </TableRow>) }
            
      </TableBody>
    </Table>
  </Paper>
}