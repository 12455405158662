import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ApolloProvider } from '@apollo/react-hooks';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sv'

import client from './clients/apollo-client';
import LoginPage from './Login';
import Dashboard from './Dashboard';
import AuthContext, { AuthProvider } from "./contexts/AuthContext";

dayjs.locale('sv')

const PrivateRoute = ({ children, ...rest }) => {
  const { token } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const InitialRoute = () => {
  const { token } = useContext(AuthContext);

  return (
    <Route
      render={({ location }) =>
        token ? (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location }
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const App = () => {
  return (
    <MuiPickersUtilsProvider utils={DayJsUtils}>
      <AuthProvider>
        <ApolloProvider client={client}>
          <Router>
            <div style={{ width: '100%', height: '100%' }}>
              <Switch>
                <Route exact path="/">
                  <InitialRoute />
                </Route>
                <Route path="/login">
                  <LoginPage />
                </Route>
                <PrivateRoute path="/dashboard">
                  <Dashboard />
                </PrivateRoute>
              </Switch>
            </div>
          </Router>
        </ApolloProvider>
      </AuthProvider>
    </MuiPickersUtilsProvider>
  );
}
export default App;