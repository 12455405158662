import React from 'react';
import { Paper, Typography } from '@material-ui/core';


export default () => {
  return <Paper style={{ margin: 20, marginTop: 90, display: 'flex', flex: 1, padding: 20, flexDirection: 'column' }}>
    <Typography variant="h4">
            Hej och välkommen till ICA-klassikerns admin-gränssnitt!
    </Typography>
    <Typography variant="body1" style={{ marginTop: 20 }}>
            Menyn till vänster är anpassad efter din/dina roller. Kontakta <a href="mailto:nina@ensvenskklassiker.se">nina@ensvenskklassiker.se</a> om du behöver mer tillgång än vad du har i nuläget.
    </Typography>
  </Paper>
}