import React, { useState, useEffect } from 'react';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Chip, Button, Fab, TextField, DialogContentText, DialogContent, DialogTitle, Dialog, Switch, LinearProgress, DialogActions, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import EditIcon from '@material-ui/icons/Edit'

const USERS_TO_MANAGE = gql`
    query UsersToManage {
  admin{
    usersToManage {
      _id
      email
      adminRoles{
        _id
        roleType
      }
    }
  }
}
`

const SET_USER = gql`
  mutation SET_USER ($email:String!, $roleTypes:[RoleType!]!) {
    setAdminUser(email:$email, roleTypes: $roleTypes)
  }
`

const RoleTypes = [
  'OWNER',
  'PARTNER_ADMIN',
  'OPERATIONS',
  'DEVELOPER'
]

const EditUserDialog = ({ isOpen, onClose, user }) => {
  const [setUser, { loading, error }] = useMutation(SET_USER, {
          refetchQueries: ['UsersToManage']
        }),
        [email, setEmail] = useState(''),
        [roleTypes, setRoleTypes] = useState([]);

  const registerInner = async () => {
    await setUser({ variables: { email, roleTypes } });
    onClose();
  }
  
  const register = (evt) => {
    registerInner();
    evt.preventDefault();
    evt.stopPropagation();
    return false;
  }

  useEffect(() => {
    setEmail(user ? user.email : '');
    setRoleTypes(user && user.adminRoles ? user.adminRoles.map(r => r.roleType): []);
  }, [user])
  
  useEffect(() => {
    if(error){
      alert(error.message);
    }
  }, [error]);
  
  return <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
    <form onSubmit={register} noValidate>
      <DialogTitle id="form-dialog-title">Användarrättigheter</DialogTitle>
      <DialogContent>
        <DialogContentText>
              Här ger du en användare tillgång baserat på deras epost-address
        </DialogContentText>
        <TextField
          autoFocus
          id="email"
          label="Email"
          disabled={!!(user && user.email)}
          fullWidth
          value={email}
          onChange={evt => setEmail(evt.target.value)}
          required
        />
        <List>
          { RoleTypes.map(rt => <ListItem key={rt} >
            <ListItemText primary={rt}></ListItemText>
            <ListItemSecondaryAction>
              <Switch checked={roleTypes.includes(rt)} onChange={() => {
                if(roleTypes.includes(rt)){
                  setRoleTypes(rts => rts.filter(roleType => roleType !== rt))
                } else {
                  setRoleTypes(rts => [...rts, rt])
                }
              }} /></ListItemSecondaryAction></ListItem>) }
        </List>
        { loading ? <LinearProgress /> : null }
            
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
              Avbryt
        </Button>
        <Button type='submit' color="primary" variant='contained' disabled={loading}>
              Spara
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}



const Users = () => {
  const { data, loading, error } = useQuery(USERS_TO_MANAGE),
        [editingUser, setEditingUser ] = useState(null);

  return <Paper style={{ width: '100%', margin: 20, marginTop: 90 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
                  
          </TableCell>
          <TableCell>
                  Email
          </TableCell>
          <TableCell>
                  Roller
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { data && data.admin && data.admin.usersToManage ? data.admin.usersToManage.map(u => <TableRow key={u._id}>
          <TableCell>
            <Fab color="primary" aria-label="edit" size='small' onClick={() => setEditingUser(u)}>
              <EditIcon />
            </Fab>
          </TableCell>
          <TableCell>
            { u.email }
          </TableCell>
          <TableCell>
            { u.adminRoles.map(role => <Chip key={role._id} label={ role.roleType } size='small' style={{ margin: 5 }} />) }
          </TableCell>
        </TableRow>) : [] }
      </TableBody>

    </Table>
    { error ? <Typography color='error'>{ error.message } </Typography> : null }
    { loading ? <LinearProgress /> : null }
    <Button color='primary' variant='contained' style={{ margin: 20 }} onClick={() => setEditingUser({})}>Bjud in ny användare</Button>
    <EditUserDialog isOpen={!!editingUser} user={editingUser} onClose={() => setEditingUser(null)} />
  </Paper>
}

export default Users;