import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import jwt from 'jsonwebtoken';

const AuthContext = createContext({ 
  token: null, 
  setToken: (token: string) => {
    console.log(token)
  },
  logout: () => {}
});

type DecodedToken = {
  _id: string;
  adminRoles: [{ _id: string; roleType: 'OWNER' | 'PARTNER_ADMIN' | 'OPERATIONS' | 'DEVELOPER' }];
  iat: number;
}

export const STORAGE_KEY = 'authToken'
export const EXPIRE_SESSION_AFTER = 2 * 60 * 60 * 1000;
export const timeUntilTokenExpires = (token: DecodedToken): number => token.iat * 1000 + EXPIRE_SESSION_AFTER - Date.now();

export const logoutWithMessage = (): void => {
  localStorage.removeItem(STORAGE_KEY);
  window.alert('Din session har gått ut - var god logga in igen');
  window.location.href = '/';
}

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    if(token){
      localStorage.setItem(STORAGE_KEY, token);
    }
  }, [token]);

  useEffect(() => {
    const savedToken = localStorage.getItem(STORAGE_KEY);
    setToken(savedToken);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY);
    setToken(null);
  }, []);

  return <AuthContext.Provider value={{ token, setToken, logout }}>{ children }</AuthContext.Provider>;
};

export const useToken = (): string | null => {
  const { token } = useContext(AuthContext);

  return token;
}

export const useDecodedToken = (): DecodedToken => {
  const token = useToken();

  const decodedToken = useMemo(() => 
    jwt.decode(token)
  , [token]);

  return decodedToken;
}

export const useRoles = () => {
  const decodedToken = useDecodedToken();

  return decodedToken.adminRoles || [];
}

export const useHasRole = (...roleTypes) => {
  const roles = useRoles();

  return roles.find(r => roleTypes.includes(r.roleType));
}

export const useLogout = () => {
  const { logout } = useContext(AuthContext);

  return logout;
}

export default AuthContext;
