import React, {useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from  '@material-ui/icons/People';
import ListItem from '@material-ui/core/ListItem';
import TransformIcon from '@material-ui/icons/Transform'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CodeIcon from '@material-ui/icons/Code';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import BugReportIcon from '@material-ui/icons/BugReport'
import StorageIcon from '@material-ui/icons/Storage'
import CloudIcon from  '@material-ui/icons/Cloud'
import Link from '@material-ui/core/Link'
import useUpdateInterval from 'kingstinct/hooks/useUpdateInterval';
import DescriptionIcon from  '@material-ui/icons/Description'
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  useLocation
} from "react-router-dom";
import Content from './Content';

import { useLogout, useHasRole, useDecodedToken, logoutWithMessage, timeUntilTokenExpires } from '../contexts/AuthContext';
import Partner from './Partner';
import PartnerDocs from './PartnerDocs';
import Users from './Users';
import Push from './Push';
import PlaygroundComponent from './Playground';
import Jobs from './Jobs';
import Start from './Start';
import PartnerStats from './PartnerStats';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const SessionTimer = () => {
  const token = useDecodedToken(),
        msUntilLogout = useUpdateInterval(() => timeUntilTokenExpires(token) , 1000),
        totalSeconds = Math.floor(msUntilLogout / 1000),
        seconds = totalSeconds % 60,
        minutes = Math.floor(totalSeconds / 60) % 60,
        hours = Math.floor(totalSeconds / 3600),
        timeString = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    

  if(msUntilLogout <= 0){
    logoutWithMessage();
  }

  return <Typography>{ timeString }</Typography>
}

const Dashboard = () => {
  const classes = useStyles({}),
        [open, setOpen] = useState(true);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
          setOpen(false);
        },
        isOwner = useHasRole('OWNER'),
        isOperations = useHasRole('OPERATIONS'),
        isDeveloper = useHasRole('DEVELOPER'),
        isPartnerAdmin = useHasRole('PARTNER_ADMIN'),
        logout = useLogout(),
        { path, url } = useRouteMatch(),
        location = useLocation();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Klassikern Admin
          </Typography>
          <SessionTimer />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        style={{ height: '100%' }}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List> <div>
          <ListItem component={NavLink} 
            button to={url} selected={location.pathname === url}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Startsida" />
          </ListItem>
          { isPartnerAdmin ? <ListItem component={NavLink} 
            button to={url + '/partner'} selected={location.pathname === url + '/partner'}>
            <ListItemIcon>
              <TransformIcon />
            </ListItemIcon>
            <ListItemText primary="Partner" />
          </ListItem> : null }
          { isPartnerAdmin ? <ListItem component={NavLink} 
            button to={url + '/partner-docs'} selected={location.pathname === url + '/partner-docs'}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Partner Docs" />
          </ListItem> : null }
          { isOwner ? <ListItem component={NavLink} 
            button to={url + '/partner-stats'} selected={location.pathname === url + '/partner-stats'}>
            <ListItemIcon>
              <AssessmentIcon />  
            </ListItemIcon>
            <ListItemText primary="Partner Stats" />
          </ListItem> : null }
          { isOwner ? <ListItem component={NavLink} 
            button to={url + '/users'} selected={location.pathname === url + '/users'}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Användare" />
          </ListItem> : null }
          { isOwner ? <ListItem component={NavLink} 
            button to={url + '/push'} selected={location.pathname === url + '/push'}>
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Push" />
          </ListItem> : null }
          { isOperations ? <ListItem component={NavLink} 
            button to={url + '/graphql'} selected={location.pathname === url + '/graphql'}>
            <ListItemIcon>
              <CodeIcon />
            </ListItemIcon>
            <ListItemText primary="GraphQL" />
          </ListItem> : null }
          { isOperations ? <ListItem component={NavLink} 
            button to={url + '/jobs'} selected={location.pathname === url + '/jobs'}>
            <ListItemIcon>
              <TimelapseIcon />
            </ListItemIcon>
            <ListItemText primary="Jobb" />
          </ListItem> : null }
    
        </div></List>
        <Divider />
        <List><div>
          { isOperations ? <ListItem 
            component={Link} href={'https://cloud.mongodb.com'}  target='_blank'>
            <ListItemIcon><StorageIcon></StorageIcon></ListItemIcon>
            <ListItemText primary="MongoDB Cloud" />
          </ListItem> : null }
          { isOperations ? <ListItem 
            component={Link} href={'https://dashboard.heroku.com/apps/klassikern'}  target='_blank'>
            <ListItemIcon>
              <CloudIcon />
            </ListItemIcon>
            <ListItemText primary="Heroku" />
          </ListItem> : null }
          { isOperations ? <ListItem 
            component={Link} href={'https://klassikern.loggly.com/'}  target='_blank'>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Loggly" />
          </ListItem> : null }
          { isOperations ? <ListItem 
            component={Link} href={'https://app.bugsnag.com/kingstinct-ab/klassikern'}  target='_blank'>
            <ListItemIcon>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText primary="Bugsnag" />
          </ListItem> : null }
          { isDeveloper ? <ListItem 
            component={Link} href={'https://bitbucket.org/account/user/kingstinct/projects/KL'}  target='_blank'>
            <ListItemIcon>
              <CodeIcon />
            </ListItemIcon>
            <ListItemText primary="Bitbucket" />
          </ListItem> : null }
          { isDeveloper ? <ListItem 
            component={Link} href={'https://app.bitrise.io'}  target='_blank'>
            <ListItemIcon>
              <MobileFriendlyIcon />
            </ListItemIcon>
            <ListItemText primary="Bitrise" />
          </ListItem> : null }
          
        </div></List>
        <Divider />
        <List><div>
          <ListItem button onClick={() => logout()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logga ut" />
          </ListItem></div></List>
      </Drawer>
      <Switch>
        <Route exact path={path}>
          <Start />
        </Route>
        <Route path={`${path}/users`}>
          <Users />
        </Route>
        <Route path={`${path}/reports`}>
          <Content />
        </Route>
        <Route path={`${path}/partner`}>
          <Partner />
        </Route>
        <Route path={`${path}/partner-stats`}>
          <PartnerStats />
        </Route>
        <Route path={`${path}/push`}>
          <Push />
        </Route>
        <Route path={`${path}/graphql`}>
          <PlaygroundComponent />
        </Route>
        <Route path={`${path}/jobs`}>
          <Jobs />
        </Route>
        <Route path={`${path}/partner-docs`}>
          <PartnerDocs />
        </Route>
      </Switch>
    </div>
  );
}

export default Dashboard;