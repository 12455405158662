import * as development from './env.dev';
import * as production from './env.prod';
import * as staging from './env.staging';
import * as stagingStudent from './env.staging-student';

const configs = {
  development,
  production,
  staging,
  stagingStudent
}

console.log('process.env', process.env);

const deploymentStage = process.env.REACT_APP_DEPLOYMENT_STAGE,
      config = configs[deploymentStage] || development;

export const {
  BACKEND_BASE_URL
} = config;

export default config;