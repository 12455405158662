import React, { useState } from 'react';
import { TextField, Paper, Button, Table, TableHead, TableRow, TableBody, TableCell, TablePagination, LinearProgress } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { DateTimePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';

const SCHEDULE_NOTIFICATION = gql`
mutation($title:String!, $message:String!, $sendAfter: Date!) {
  scheduleNotification(title:$title, message:$message, sendAfter: $sendAfter, sendToAll: true)
}
`
const ALL_NOTIFICATIONS = gql`
query allNotifications($offset:Int, $first:Int){
  allNotifications(offset:$offset, first:$first){
    totalCount
    edges{
      cursor
      node{
        title
        message
        remaining
        successful
        send_after
      }
    }
  }
}`


const PageSize = 5;

const Push = () => {
  const [title, setTitle] = useState(''),
        [message, setMessage] = useState(''),
        [page, setPage] = useState(0),
        [deliverAt, setDeliverAt] = useState(dayjs()),
        [sendNotification] = useMutation(SCHEDULE_NOTIFICATION, { variables: { title, message, sendAfter: deliverAt ? deliverAt.toISOString() : null }, refetchQueries: ['allNotifications'] }),
        { data, loading } = useQuery(ALL_NOTIFICATIONS, { variables: { first: PageSize, offset: page * PageSize }, fetchPolicy: "cache-and-network", pollInterval: 60000 }),
        submit = (event) => {
          event.preventDefault();
          sendNotification();
          setTitle('');
          setDeliverAt(dayjs());
          setMessage('');
          return false;
        };

  return  <Paper style={{ width: '100%', margin: 20, padding: 20, marginTop: 90 }}>
    <form onSubmit={submit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="title"
        value={title}
        onChange={evt => setTitle(evt.target.value)}
        label="Titel"
        id="title"
      />
      <span>{ title.length + ' / 25' }</span>
      <TextField
        variant="outlined"
        margin="normal"
        rowsMax={4}
        required
        fullWidth
        value={message}
        onChange={evt => setMessage(evt.target.value)}
        name="message"
        label="Meddelande"
        id="message"
        multiline
        rows={2}
      />
      <span>{ message.length + ' / 150' }</span>
      <DateTimePicker
        variant="inline"
        openTo="date"
        label="Välj tidpunkt"
        ampm={false}
        fullWidth
        disablePast
        value={deliverAt}
        format={'YYYY-MM-DD HH:mm'}
        onChange={setDeliverAt}
      />
    
      <Button color='primary' type="submit" variant='contained' style={{ marginTop: 20 }}>Skicka till alla användare</Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              Title
            </TableCell>
            <TableCell>
              Meddelande
            </TableCell>
            <TableCell>
              Tidpunkt
            </TableCell>
            <TableCell>
              Levererade
            </TableCell>
            <TableCell>
              Återstående
            </TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          
          { data && data.allNotifications.edges.map(({ node , cursor}) => {
            return <TableRow key={cursor}>
              <TableCell>
                { node.remaining === 0 ? <CheckCircleIcon style={{ color: 'green' }} /> : node.send_after > (Date.now() / 1000) ? <ScheduleIcon style={{ color: 'orange' }} /> : <LabelImportantIcon style={{ opacity: 0.5, color: 'green' }} />  }
              </TableCell>
              <TableCell>
                { node.title }
              </TableCell>
              <TableCell>
                { node.message }
              </TableCell>
              <TableCell>
                { node.send_after ? new Date(node.send_after * 1000).toLocaleString() : null }
              </TableCell>
              <TableCell>
                { node.successful }
              </TableCell>
              <TableCell>
                { node.remaining }
              </TableCell>
            </TableRow>
          }) }
        </TableBody>
      </Table>
      { <LinearProgress style={{ width: '100%', opacity: loading ? 1 : 0 }}  /> }
      <TablePagination
        component="div"
        count={data ? data.allNotifications.totalCount : 0}
        rowsPerPage={PageSize}
        page={page}
        onChangePage={(evt, page) => setPage(page)}
      />
      
    </form>

  </Paper>
}

export default Push;