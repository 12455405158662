import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import EditIcon from '@material-ui/icons/Edit'
import LinkIcon from '@material-ui/icons/Link'
import { Button, Link, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, LinearProgress, Table, TableHead, TableBody, TableCell, TableRow, Fab } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const OrganizationFragment = gql`
  fragment OrganizationFragment on PartnerOrganization {
    _id
    name
    apiToken
    organizationNumber
    lastSuccessfulRequest
    lastSuccessfulSimulationRequest
  }
`


const UPDATE_ORGANIZATION = gql`
mutation updatePartnerOrganization($name:String!, $organizationNumber:String!, $partnerOrganizationId:MongoID!){
  updatePartnerOrganization(name:$name, organizationNumber:$organizationNumber, partnerOrganizationId: $partnerOrganizationId){
    ...OrganizationFragment
  }
}
${OrganizationFragment}
`

const CREATE_ORGANIZATION = gql`
mutation createPartnerOrganization($name:String!, $organizationNumber:String!){
  createPartnerOrganization(name:$name, organizationNumber:$organizationNumber){
    ...OrganizationFragment
  }
}
${OrganizationFragment}
`

const ORGANIZATION_LIST = gql`
query OrganizationList {
  admin{
    partnerOrganizations{
      ...OrganizationFragment
    }
  }
}
${OrganizationFragment}
`

const OrganizationDialog = ({ isOpen, onClose, organization }) => {
  const [createOrganisation, { data: createData, loading: createLoading, error: createError }] = useMutation(CREATE_ORGANIZATION, {
          refetchQueries: ['OrganizationList']
        }),
        [updateOrganisation, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(UPDATE_ORGANIZATION, {
          refetchQueries: ['OrganizationList']
        }),
        [name, setName] = useState(organization ? organization.name : ''),
        data = createData || updateData,
        loading = createLoading || updateLoading,
        error = createError || updateError,
        [organizationNumber, setOrganizationNumber] = useState(organization ? organization.organizationNumber : '');

  const register = (evt) => {
    if(organization){
      updateOrganisation({ variables: { name, organizationNumber, partnerOrganizationId: organization._id } });
    }
    else {
      createOrganisation({ variables: { name, organizationNumber } });
    }
    evt.preventDefault();
    evt.stopPropagation();
    return false;
  }

  useEffect(() => {
    if(data){
      onClose();
    }
  }, [data, onClose]);

  useEffect(() => {
    if(error){
      alert(error.message);
    }
  }, [error]);

  return <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
    <form onSubmit={register} noValidate>
      <DialogTitle id="form-dialog-title">Registrera partnerorganisation</DialogTitle>
      <DialogContent>
        <DialogContentText>
            När du skapat en partnerorganisation får du en API-nyckel som du använder för att registrera användare du vill ge ett medlemsskap i ICA-klassikern.
        </DialogContentText>
        <TextField
          autoFocus
          id="name"
          label="Namn"
          fullWidth
          value={name}
          onChange={evt => setName(evt.target.value)}
          required
        />
        <TextField
          id="orgnr"
          label="Organisationsnummer"
          fullWidth
          value={organizationNumber}
          onChange={evt => setOrganizationNumber(evt.target.value)}
          required
        />
        { loading ? <LinearProgress /> : null }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
            Avbryt
        </Button>
        <Button type='submit' color="primary" variant='contained' disabled={loading}>
            Spara
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}

const OrganizationRow = ({ organization: o }) => {
  const [showToken, setShowToken] = useState(false),
        [editOrganization, setEditOrganization] = useState(false),
        textareaRef = useRef(null);

  const onClose = () => setShowToken(false);

  return <>
    <TableRow key={o._id} hover role="button">
      <TableCell>
        <Fab color="primary" aria-label="edit" size='small' onClick={() => setEditOrganization(true)}>
          <EditIcon />
        </Fab>
      </TableCell>
      <TableCell>{ `${o.name} (${o.organizationNumber})` }</TableCell>
      <TableCell>
        <Button color='primary' onClick={() => setShowToken(t => !t)} variant='text'>Visa</Button>
      </TableCell>
    </TableRow>
    <Dialog open={showToken} fullWidth>
      <DialogContent>
        <DialogContentText>{ `API-nyckel för ` + o.name }</DialogContentText>
        <TextField aria-label="maximum width" multiline rows={1} fullWidth ref={textareaRef} value={o.apiToken}></TextField>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
            Stäng
        </Button>
        <Button onClick={() => {
          //copyToClipboard(o.apiToken)
          textareaRef.current.querySelector('textarea').select();
          document.execCommand('copy');

          onClose();
        }} color="primary" variant='contained'>
            Kopiera
        </Button>
      </DialogActions>
    </Dialog>
    <OrganizationDialog isOpen={editOrganization} onClose={() => setEditOrganization(false)} organization={o} />
  </>
}




const Partner = () => {
  const classes = useStyles({}),
        fixedHeightPaper = clsx(classes.paper, classes.fixedHeight),
        [isDialogOpen, setIsDialogOpen] = useState(false),
        {data, loading, error} = useQuery(ORGANIZATION_LIST, {
          fetchPolicy: 'cache-and-network'
        });

  const createOrganization = () => {
    setIsDialogOpen(true);
  }

  return <main className={classes.content}>
    <div className={classes.appBarSpacer} />
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h5" component="h5">
              Välkommen bästa partner!
            </Typography>
            <Typography variant="body1">
              Här hittar du det du behöver för att koppla ditt system mot ICA-klassikern.
            </Typography>

            <List dense className={classes.rootList}>

              <ListItem button onClick={createOrganization}>
                <ListItemIcon>
                  <Checkbox
                    onChange={createOrganization}
                    checked={!!(data && data.admin && data.admin.partnerOrganizations && data.admin.partnerOrganizations.length > 0)}
                  />
                </ListItemIcon>
                <ListItemText primary='1. Registrera din partnerorganisation och få din API-nyckel' />
              </ListItem>
              <ListItem button component={Link} href="/dashboard/partner-docs">
                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    //onChange={handleToggle(value)}
                    checked={!!(data && data.admin && data.admin.partnerOrganizations && data.admin.partnerOrganizations.find(o => o.lastSuccessfulSimulationRequest))}
                  />
                </ListItemIcon>
                <ListItemText primary='2. Läs dokumentationen och experimentera med APIet i simuleringsläge' />
                <LinkIcon />
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    //onChange={handleToggle(value)}
                    checked={!!(data && data.admin && data.admin.partnerOrganizations && data.admin.partnerOrganizations.find(o => o.lastSuccessfulRequest))}
                  />
                </ListItemIcon>
                <ListItemText primary='3. Implementera och anropa vårt API i produktion' />
              </ListItem>

            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>

          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Dina partnerorganisationer</Typography>
            { loading && !data ? <LinearProgress /> :
              error ? <Typography color='error'>{ error.message }</Typography> 
                : <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Namn</TableCell>
                      <TableCell>API-nyckel</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { data && data.admin && data.admin.partnerOrganizations ? data.admin.partnerOrganizations.map(organization => <OrganizationRow key={organization._id} organization={organization} />) : null }
                  </TableBody>
                </Table> }
            <Button onClick={createOrganization} color='primary' style={{ margin: 20, maxWidth: 360 }} variant='contained'>Registrera ny organisation</Button>
          </Paper>
        </Grid>
      </Grid>
      <OrganizationDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} organization={null} />
    </Container>
  </main>
}

export default Partner;
