import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import Link from '@material-ui/core/Link';

import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Redirect,
  useLocation
} from "react-router-dom";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import AuthContext from './contexts/AuthContext';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ensvenskklassiker.se/">
        En Svensk Klassiker
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginRequestMutation = gql`
  mutation($email:String!) {
    loginAdminRequest(email:$email)
  }
`

const LoginConfirmMutation = gql`mutation($email:String!, $code:String!) {
  loginAdminConfirm(email:$email, code:$code)
}`


export default () => {
  const classes = useStyles({}),
        location = useLocation(),
        { setToken, token } = useContext(AuthContext),
        [loginRequest, { data: loginRequestData, loading: isRequestLoading, error: requestError }] = useMutation(LoginRequestMutation),
        [loginConfirm, { data: loginConfirmData, loading: isConfirmLoading, error: codeError }] = useMutation(LoginConfirmMutation),
        { from } = location.state || { from: { pathname: "/" } },
        [email, setEmail] = useState(''),
        [code, setCode] = useState('');

  useEffect(() => {
    if(loginConfirmData){
      const { loginAdminConfirm } = loginConfirmData;
      setToken(loginAdminConfirm);
    }
  }, [loginConfirmData, setToken]);
       
  return token ? <Redirect to={from} /> : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Logga in
        </Typography>
        { loginRequestData ? <form className={classes.form} noValidate onSubmit={(evt) => {
          loginConfirm({ variables: { email, code } });
          evt.preventDefault();
          evt.stopPropagation();
          return false;
        }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            error={!!codeError}
            helperText={codeError && codeError.message}
            fullWidth
            name="code"
            label="Inloggningskod"
            type="password"
            id="code"
            onChange={(evt)=> setCode(evt.target.value)}
            autoComplete="one-time-code"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isConfirmLoading}
            className={classes.submit}
          >
            Logga in
          </Button>
        </form> :  <form className={classes.form} noValidate onSubmit={(evt) => {
          loginRequest({ variables: { email } });
          evt.preventDefault();
          evt.stopPropagation();
          return false;
        }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={!!requestError}
            helperText={requestError && requestError.message}
            id="email"
            label="Epost-address"
            onChange={(evt)=> setEmail(evt.target.value)}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isRequestLoading}
            className={classes.submit}
          >
            Begär inloggningskod
          </Button>
        </form>
        }
        { isConfirmLoading || isRequestLoading ? <LinearProgress style={{ width: '100%' }} /> : null }
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}