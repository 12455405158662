import React from 'react';
import { Provider } from 'react-redux'

import { Playground, store } from 'graphql-playground-react'
import { useToken } from '../contexts/AuthContext';
import { BACKEND_BASE_URL } from '../config';

const PlaygroundComponent = () => {
  const token = useToken();

  return (<div style={{ marginTop: 60, width: '100%' }}><Provider store={store}>
    <Playground endpoint={BACKEND_BASE_URL + '/api'}
      canSaveConfig 
      settings={{ 'editor.theme': 'light' }}
      style={{ width: '100%' }} 
      headers={{ authorization: 'Bearer ' + token }} />
  </Provider></div>);
}

export default PlaygroundComponent;