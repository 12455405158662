import ApolloClient from 'apollo-boost';
import { STORAGE_KEY } from '../contexts/AuthContext';
import { BACKEND_BASE_URL } from '../config';

const client = new ApolloClient({
  uri: BACKEND_BASE_URL + '/api',
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if(extensions && extensions.code === 'UNAUTHENTICATED'){
          localStorage.removeItem(STORAGE_KEY);
          window.alert(message);
          window.location.href = '/';
        }

        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  },
  request: (operation) => {
    const token = localStorage.getItem(STORAGE_KEY);
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })
  }
});

export default client;
